.container {
  height: 100%;
  width:auto;


  display: flex;
  align-items: stretch;

  flex-direction: column;
  margin-bottom: 10px;
  padding: 25px;
}

.button {
  border: none;
  outline-color: white;
  width: fit-content;

  background: none;
}

.logo_routing {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;

  padding-bottom: 10px;
  align-items: stretch;
}
.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 25px;

  flex-basis: 100%;
}
.logoContainer{
  width: auto;
  height:50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.abLogoImageContainer {
  height: 50px;
  width: 45px;
}
.logoName{
  padding-left:13px;
  font-weight: 900;
  font-size: var(--font-sm);
}
.abLogoImage {
  height: 100%;
  width: 100%;
}

.socialMedia {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  height: auto;
  min-height: 5vh;
  margin-left: 25px;
}

.linkContainer {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  flex-basis: 80%;
}

.link {
  display: flex;
  flex-direction: column;
}

.copyrightText {
  font-size: var(--font-xs);
  font-weight: 500;
  margin: auto;
  padding-top: 10px;
  cursor: default
}
.icon {
  padding: 8px;
}
@media (min-width: 768px) {
  .logo {
    width: 400px;
    flex: 1;
  }
  .linkContainer {
    flex: 1;
  }
  .link {
    flex: 1;
  }

  .logo_routing {
    flex-basis: 100%;
  }
}

@media (min-width: 1440px) {
  .icon{
    padding: 10px
  }
  .socialMedia{
    height: 10vh;
  }
}
@media (min-width: 1900px){
  .socialMedia{
    height: 13vh
  }
  .icon{
    padding: 25px
  }
}
